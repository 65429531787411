<template>
  <edit-item-component endpoint="wows" type="wow"/>
</template>

<script>
export default {
  name: "Edit"
}
</script>

<style scoped>

</style>